import {initClient} from '@/script/sharedWorker.mjs'
import WorkerToken from './token.worker.mjs?sharedworker'

export let token = null

const worker = new WorkerToken
initClient(worker)

worker.port.onmessage = e => {
    token = e.data
}

export const initToken = () => {
    const item = window.localStorage.getItem('token')

    if (! item) {
        return
    }

    try {
        token = JSON.parse(item)
        worker.port.postMessage(token)
    }
    catch (err) {
        console.err(err)
    }
}

export const setToken = newToken => {
    if (newToken === token) {
        return
    }

    token = newToken
    worker.port.postMessage(token)

    if (newToken) {
        const item = JSON.stringify(token)
        window.localStorage.setItem('token', item)
    }
    else {
        window.localStorage.removeItem('token')
    }
}

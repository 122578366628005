const KEEP_ALIVE = 300000 // 5 minutes
const HEARTBEAT_INTERVAL = 60000 // 1 minute

export const initClient = worker => {
    const id = setInterval(
        () => worker.port.postMessage('HEARTBEAT'),
        HEARTBEAT_INTERVAL
    )

    return () => {
        worker.port.close()
        clearInterval(id)
    }
}

export const initServer = worker => {
    worker.ports = new Set

    worker.addEventListener('connect', e => {
        const [p] = e.ports
        worker.ports.add(p)
        let id = 0

        const clean = () => {
            id = setTimeout(
                () => {
                    p.close()
                    worker.ports.delete(p)
                },

                KEEP_ALIVE
            )
        }

        clean()

        p.addEventListener('message', e => {
            if ('HEARTBEAT' === e.data) {
                e.stopImmediatePropagation()
                clearTimeout(id)
                clean()
            }
        })

        p.start()
    })
}

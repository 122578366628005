import { Tag } from 'antd';

const StateData = ({ value }) => {
    switch (value) {
        case "DRAFT":
            return <Tag color="#DCDCDC">草稿</Tag>;
        case "REVISE":
            return <Tag color="blue">修订</Tag>;
        case "RLS":
            return <Tag color="green">发布</Tag>;
        case "DISCARD":
            return <Tag color="red">废弃</Tag>;
    }
    return <div>{value}</div>;
}

export default StateData;